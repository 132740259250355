import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import getStyles from './styles';
import { useStyles } from 'react-styles-hook';

const LateralFilterCategories = ({ categoryName, labels, parentItems }) => {

  const classes = useStyles(getStyles());

  return (
    <div>
      {/* parentCategory navigation link showing if checkbox is checked*/}
      {parentItems.map((page) => (
        <div className="flex">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-black mt-1" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
          <Link
            to={page.url}
            className="font-medium text-sm text-black pl-3"
          >
            {page.label}
          </Link>
        </div>
      ))}
      {/* currentCategory link for navigation */}
      <div className="flex pl-7">
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-black mt-1" viewBox="0 0 20 20" fill="currentColor">
          <path
            fillRule="evenodd"
            d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
            clipRule="evenodd"
          />
        </svg>
        {parentItems.map((page) => (
          <Link to={page.url} className="font-medium text-sm text-black pl-3">
            {categoryName}
          </Link>
        )).slice(-1)}
      </div>

      {/* checkbox section */}
      {labels.map((label) => (
        (label?.node?.parentCategory?.categoryEncartTitle || label?.node?.parentCategory?.categoryName) === categoryName ? (
          <div className="pl-14">
            <Link to={label.node.slug}>
              <fieldset className="space-y-5">
                <div className="relative flex items-start">
                  <div className="flex items-center h-5 pt-3">
                    <input
                      id={label.node.categoryEncartTitle ? label.node.categoryEncartTitle : label.node.categoryName}
                      aria-describedby={label.node.categoryEncartTitle ? label.node.categoryEncartTitle : label.node.categoryName}
                      name={label.node.categoryEncartTitle ? label.node.categoryEncartTitle : label.node.categoryName}
                      type="checkbox"
                      style={classes.checkbox}
                      className="w-5 h-5 text-orange-dark bg-orange-dark"
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor={label.node.categoryEncartTitle ? label.node.categoryEncartTitle : label.node.categoryName} className="text-black">
                      {label.node.categoryEncartTitle ? label.node.categoryEncartTitle : label.node.categoryName}
                    </label>
                  </div>
                </div>
              </fieldset>
            </Link>
          </div>
        )
          :
          null
      ))}
    </div>
  )
};

export default LateralFilterCategories

LateralFilterCategories.propTypes = {
  categoryName: PropTypes.string.isRequired,
  labels: PropTypes.array.isRequired,
  parentItems: PropTypes.array.isRequired
};
