import i18n from "../../../i18n";
import React, { useState, useEffect, Fragment } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { useSelector, useDispatch } from 'react-redux'
import Layout from "../../components/Layout";
import Paging from "../../components/Paging";
import NavBarMenu from '../../components/NavBarMenu';
import Breadcrumb from "../../components/Breadcrumb";
import CoverImage from "../../components/CoverImage";
import ProductListCard from "../../components/ProductListCard";
import PriceFilter from "../../components/PriceFilter";
import Loader from "../../components/Loader";
import DropdownSelector from "../../components/DropdownSelector";
import LateralFilterCategories from '../../components/LateralFilter/LateralFilterCategories';
import LateralFilterSellers from '../../components/LateralFilter/LateralFilterSellers';
import LateralFacetFilter from '../../components/LateralFilter/LateralFacetFilter';
import { searchLaunched } from "./reducer";
import getStyles from "./styles";
import { useStyles } from "react-styles-hook";
import SnackBar from '../../components/SnackBar';
import { Transition } from '@headlessui/react';
import Subtitle from "../../components/Subtitle"
import { Pagination } from "../../components/Pagination";

function generateTotalCategoryHierarchy(node) {
  if (!node) return [];
  let prefix = [];
  if (typeof node.parentCategory !== undefined) {
    prefix = generateTotalCategoryHierarchy(node.parentCategory);
  }
  prefix.push(node);
  return prefix;
}

function generateTotalSlug(node) {
  if (!node) return "";
  let prefix = "";
  if (typeof node.parentCategory !== undefined) {
    prefix = generateTotalSlug(node.parentCategory);
  }
  return prefix + "/" + node.slug;
}

const PageList = ({ data, pageContext }) => {
  const classes = useStyles(getStyles());
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const dispatch = useDispatch();

  const { slug: currentCategorySlug, locale: searchLocale, sellerIds: searchSellerIds } = pageContext;
  const currentCategory = data.currentCategory.edges[0].node;
  // const searchCategoryPath = generateTotalCategoryHierarchy(currentCategory).map(c => c.slug).join("/");
  const searchCategoryPath = currentCategory.slug; // we need slug of last element of currentPath
  const [searchKey, setSearchKey] = useState(null);
  const [searchPage, setSearchPage] = useState(null);
  const [searchPriceMin, setSearchPriceMin] = useState(null);
  const [searchPriceMax, setSearchPriceMax] = useState(null);
  const [searchBySeller, setSearchBySeller] = useState(null);
  const [searchFacets, setSearchFacets] = useState(null);
  // useState for filter by relevance (weight) and price (dropdown filter) :
  const [orderType, setOrderType] = useState('desc-weight');

  const { isLoading, productContents, errors, snackBarLoading, snackBarOpen, snackBarMessage, snackBarError, page, lastPage } = useSelector((state) => ({
    isLoading: state.getIn(["pageList", "isLoading"]),
    productContents: state.getIn(["pageList", "productContents"]),
    errors: state.getIn(["pageList", "errors"]),
    snackBarLoading: state.getIn(["pageList", "snackBarLoading"]),
    snackBarOpen: state.getIn(["pageList", "snackBarOpen"]),
    snackBarMessage: state.getIn(["pageList", "snackBarMessage"]),
    snackBarError: state.getIn(["pageList", "snackBarError"]),
    page: state.getIn(["pageList", "page"]),
    lastPage: state.getIn(["pageList", "lastPage"]),
  }));

  const today = new Date();

  // UseEffects used for filtering by price
  useEffect(() => {
    if (searchPriceMin || searchPriceMax || orderType || searchBySeller || searchFacets) {
      setSearchKey("0###" + searchPriceMin + searchPriceMax + orderType + searchBySeller + JSON.stringify(searchFacets));
    }
  }, [searchPriceMin, searchPriceMax, orderType, searchBySeller, searchFacets]);


  useEffect(() => {
    if (searchPage) {
      setSearchKey(searchPage + "###" + searchPriceMin + searchPriceMax + orderType + searchBySeller + searchFacets);
    }
  }, [searchPage]);

  useEffect(() => {
    if (!searchKey) return;
    const searchPageToRequest = parseInt(searchKey.split("###")[0]);
    if ((searchPriceMin || searchPriceMax || orderType || searchBySeller || searchFacets)) {
      dispatch(searchLaunched({
        searchPage: searchPageToRequest,
        searchCategoryPath,
        searchLocale,
        searchPriceMin,
        searchPriceMax,
        orderType,
        searchBySeller,
        searchFacets
      }));
    }
  }, [searchKey]);

  const currentListMinSearchPrice = 0;
  const currentListMaxSearchPrice = 300;

  // i18n used for translation
  i18n(pageContext.locale);

  const parentCategories = generateTotalCategoryHierarchy(currentCategory)
    .map((node) => ({
      url: "/" + pageContext.locale + "/produits" + generateTotalSlug(node),
      label: node.categoryEncartTitle ? node.categoryEncartTitle : node.categoryName,
    }))
    .slice(0, -1);
  let currentProductList = data.categoryProducts.edges.filter(({ node: productContent }) => {
    if (productContent.childContentfulFicheProduitProductsFieldJsonNode == null) return false;
    const variants = productContent.childContentfulFicheProduitProductsFieldJsonNode.products || [];

    let productContentHasAtLeastOneAvailableOffer = false;
    variants.map((variant) => {
      (variant.offers || []).forEach((offer) => {
        if (JSON.parse(offer.isAvailable)) {
          productContentHasAtLeastOneAvailableOffer = true;
        }
      });
    });

    return productContentHasAtLeastOneAvailableOffer;
  }).map(({ node: productContent }) => {
    const startDate = productContent.crossedPriceStartDate !== null && productContent.crossedPriceStartDate !== "null" ? new Date(productContent.crossedPriceStartDate) : null;
    const endDate = productContent.crossedPriceEndDate !== null && productContent.crossedPriceEndDate !== "null" ? new Date(productContent.crossedPriceEndDate) : null;
    const defaultVariant = productContent.childContentfulFicheProduitProductsFieldJsonNode.products.filter(variant => variant.offers.some(offer => JSON.parse(offer.isAvailable)))[0];
    const defaultImageId = defaultVariant.images[0];
    const defaultImage = ((data.categoryImages || {}).edges || [1]).filter(({ node: image }) => image.contentful_id === defaultImageId)[0]?.node || null;
    const defaultOffer = defaultVariant.offers[0];
    const defaultSeller = productContent.seller.corporateName || {};
    const defaultSellerId = productContent.cataloger || {};
    // const defaultSellerId = defaultOffer.sellerId;
    // const defaultSeller = ((data.categorySellers || {}).edges || [1]).reduce((prev, next) => next.id === defaultSellerId ? next : prev) || {};
    const urlProductPage = `/${pageContext.locale}/produits/${productContent.principalSveCategory.slug}/${productContent.slug}`
    let price = Number(defaultOffer.priceWithVat);
    let crossedPrice = Number(defaultOffer.crossedPriceWithVat);
    if ((startDate !== null || endDate !== null) && crossedPrice > 0 && crossedPrice > price && (today <= startDate || today > endDate)) {
      price = Number(defaultOffer.crossedPriceWithVat);
      crossedPrice = null;
    }
    return {
      title: productContent.title,
      id: productContent.childContentfulFicheProduitProductsFieldJsonNode.products[0].id,
      image: defaultImage || null,
      price: price,
      priceDescription: defaultOffer.priceDescription,
      crossedPrice: crossedPrice,
      corporateName: defaultSeller,
      sellerId: defaultSellerId,
      urlProductPage: urlProductPage,
      flapSelectionZawema: JSON.parse(productContent.flapSelectionZawema),
      offerId: defaultOffer.id,
      goal: productContent.attributes.goal,
      porosity: productContent.attributes.porosity,
      careSteps: productContent.attributes.careSteps,
    }
  });
  if (productContents && productContents?.length !== 0) {
    currentProductList = productContents.map((hit) => {
      const urlProductPage = `/${pageContext.locale}/produits/${hit.categoryPath}/${hit.urlProductPage}`;
      const startDate = hit.crossedPriceStartDate !== null && hit.crossedPriceStartDate !== "null" ? new Date(hit.crossedPriceStartDate) : null;
      const endDate = hit.crossedPriceEndDate !== null && hit.crossedPriceEndDate !== "null" ? new Date(hit.crossedPriceEndDate) : null;
      let price = hit.price;
      let crossedPrice = hit.crossedPriceWithVat;
      if ((startDate !== null || endDate !== null) && crossedPrice > 0 && crossedPrice > price && (today <= startDate || today > endDate)) {
        price = hit.crossedPriceWithVat;
        crossedPrice = null;
      }
      return {
        title: hit.title,
        id: hit.productId,
        image: {
          description: hit.title,
          file: {
            url: hit.imageUrl
          }
        },
        price: price,
        priceDescription: hit.priceDescription,
        crossedPrice: crossedPrice,
        corporateName: hit.sellerName,
        sellerId: hit.sellerId,
        urlProductPage: urlProductPage,
        flapSelectionZawema: hit.flapSelectionZawema,
        offerId: hit.offerId,
        goal: hit.goal,
        porosity: hit.porosity,
        careSteps: hit.careSteps
      }
    });
  }

  // used for navBarMenu
  const sortedFirstLevelCategorySlugs = ["femme", "enfant", "homme", "coaching-capillaire"];

  // Filters sellers to avoid double values in lateral filter section :
  let sellers = [];
  let sellersGlobal;
  if (data.categoryProducts.edges.length !== 0) { // for products who came from contentful
    sellersGlobal = [...new Set(data.categoryProducts.edges.map(item => item.node.seller.corporateName))]; // all unique seller from product's category
  } else if (currentProductList) { // for product who came from algolia
    sellersGlobal = [...new Set(currentProductList.map(item => item.corporateName))]
  };
  if (sellersGlobal) {
    sellersGlobal.forEach((seller) => {
      sellers.push({
        node: {
          corporateName: seller
        }
      })
    })
  }

  // array of checked sellers
  const [selectedSellersArray] = useState([]);
  // array of checked facets
  const [selectedFacetsArray] = useState([]);

  const categoryDescription = JSON.parse(currentCategory.CategoryDescription.raw).content[0].content[0].value;

  const handleClick = (pageNumber) => {
    setSearchPage(pageNumber);
    if (pageNumber === 0) {
      setSearchKey("0###" + searchPriceMin + searchPriceMax + orderType + searchBySeller + JSON.stringify(searchFacets));
    }
  }

  const initMetas = [{
    name: 'og:url',
    content: `${process.env.GATSBY_WEBSITE_URL}/${pageContext.locale}/produits/${currentCategory.slug}`,
  },
  {
    name: 'title',
    content: `Zawema - ${currentCategory.categoryEncartTitle == null ? currentCategory.categoryName : currentCategory.categoryEncartTitle}`,
  },
  {
    name: 'og:title',
    content: `Zawema - ${currentCategory.categoryEncartTitle == null ? currentCategory.categoryName : currentCategory.categoryEncartTitle}`,
  },
  {
    name: 'description',
    content: categoryDescription || "",
  },
  {
    name: 'og:description',
    content: categoryDescription || "",
  }];

  return (
    <Layout
      lang={pageContext.locale}
      currentPath={currentCategory.slug}
      firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
        return (
          sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
        )
      })}
      secondLevelCategories={data.menuSecondLevel.edges}
      thirdLevelCategories={data.menuThirdLevel.edges}
    >
      {/* SEO */}
      <Helmet>
        <html lang={pageContext.locale} />
        <title>{`Zawema - ${currentCategory.categoryEncartTitle == null ? currentCategory.categoryName : currentCategory.categoryEncartTitle}`}</title>
        {/* Meta and OpenGraph */}
        {initMetas && initMetas.map(meta => (
          <meta key={meta.name} name={meta.name} content={meta.content} />
        ))}
      </Helmet>

      {/* Filtering section in mobile view */}
      {isOpenFilter
        && <Transition
          show={isOpenFilter}
          as={Fragment}
          enter="transition-opacity duration-150 transform"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150 transform"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="w-full absolute top-0 bg-white" style={classes.filterMobileView}>
            <div className="w-full flex flex-col sticky bottom-0">
              <div className="w-full p-6 flex justify-end">
                <button
                  className='bg-orange-dark'
                  onClick={() => setIsOpenFilter(!isOpenFilter)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="white">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className={"flex flex-wrap"} style={classes.filteringSection}>
                <div className={"w-5/6 h-full pl-5 z-50 pb-14"}>
                  <h3 className="font-medium text-semi">Trier par :</h3>
                  <DropdownSelector
                    increasingPrice={() => { setOrderType("asc-price") }}
                    decreasingPrice={() => { setOrderType("desc-price") }}
                    decreasingWeight={() => { setOrderType("desc-weight") }}
                    orderType={orderType}
                  />
                </div>
                {/* Categories navigation checkboxes */}
                <div className={"w-5/6 pl-5 pb-14 z-30"}>
                  <h3 className="font-medium text-semi">Affiner la <span className="font-medium text-semi text-orange-dark">catégorie</span></h3>
                  <LateralFilterCategories
                    categoryName={currentCategory.categoryEncartTitle == null ? currentCategory.categoryName : currentCategory.categoryEncartTitle}
                    parentItems={parentCategories}
                    labels={data.labelCategories.edges}
                  />
                </div>
                {/* This filter uses Algolia */}
                <div className={"w-5/6 h-full mx-auto pb-14 z-0"}>
                  <PriceFilter
                    initValues={[currentListMinSearchPrice, currentListMaxSearchPrice]}
                    onPriceChange={([min, max]) => {
                      setSearchPriceMin(min);
                      setSearchPriceMax(max);
                    }}
                    minimumPrice={searchPriceMin}
                    maxPrice={searchPriceMax}
                  />
                </div>
                {/* Filter by sellers with checkboxes */}
                <div className="w-5/6 h-full pl-5 pb-14 z-30">
                  <LateralFilterSellers
                    sellersName={sellers}
                    selectedSellersArray={selectedSellersArray}
                    filterBySeller={(el) => {
                      // create an array of selected sellers when checkbox is checked
                      const checkedSellerNameValue = el.target.attributes.value.value;
                      selectedSellersArray.includes(checkedSellerNameValue) ?
                        selectedSellersArray.splice(selectedSellersArray.indexOf(checkedSellerNameValue), 1) :
                        selectedSellersArray.push(checkedSellerNameValue);
                      setSearchBySeller([...selectedSellersArray]);
                    }}
                  />
                </div>
                {/* Facets filter */}
                <div className="w-4/5 md:w-1/4 h-full mx-auto md:mx-0 pb-14 z-30">
                  {
                    data?.facets?.edges[0]?.node?.facets?.map(facetItem => (
                      <LateralFacetFilter
                        attribute={facetItem}
                        facetFilter={(el) => {
                          const checkedFacetsValue = el.target.attributes.value.value;
                          const checkedFacetsCode = facetItem.code;

                          if (selectedFacetsArray.length === 0) {
                            selectedFacetsArray.push({ code: checkedFacetsCode, value: [checkedFacetsValue] })
                          } else if (selectedFacetsArray.length > 0) {
                            let identicalCode = false
                            let indexCode = null
                            selectedFacetsArray.map((facet, index) => {
                              if (facet.code === checkedFacetsCode) {
                                identicalCode = true
                                indexCode = index
                              }
                            })
                            if (identicalCode) {
                              selectedFacetsArray[indexCode].value.includes(checkedFacetsValue) ?
                                selectedFacetsArray[indexCode].value.splice(selectedFacetsArray[indexCode].value.indexOf(checkedFacetsValue), 1) :
                                selectedFacetsArray[indexCode].value.push(checkedFacetsValue);
                            } else {
                              selectedFacetsArray.push({ code: checkedFacetsCode, value: [checkedFacetsValue] });
                            }
                          }
                          setSearchFacets([...selectedFacetsArray]);
                        }}
                      />
                    ))
                  }
                </div>
              </div>
              <div className='w-full mb-10 flex justify-center'>
                <button
                  className="w-1/2 bg-orange-dark shadow-lg py-2 font-bold text-base"
                  onClick={() => setIsOpenFilter(!isOpenFilter)}
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </Transition>}

      {
        !isOpenFilter
        && <>
          <NavBarMenu
            firstLevelCategories={data.menuFirstLevel.edges.sort((firstLevelCategory1, firstLevelCategory2) => {
              return (
                sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory1.node.slug) - sortedFirstLevelCategorySlugs.indexOf(firstLevelCategory2.node.slug)
              )
            })}
            secondLevelCategories={data.menuSecondLevel.edges}
            thirdLevelCategories={data.menuThirdLevel.edges}
            lang={pageContext.locale}
          />
          {/* Navigation links component */}
          <Breadcrumb
            lastItemLabel={currentCategory.categoryEncartTitle ? currentCategory.categoryEncartTitle : currentCategory.categoryName} // todo check si besoin de transformer avec encartTitle , voir en dessous
            parentItems={parentCategories}
          />
          {/* Cover image and title category section with conditional rendering*/}
          <CoverImage
            imageDesktop={currentCategory.desktopImage.fluid.src}
            altDesktop={currentCategory.desktopImage.description}
            imageMobile={currentCategory.mobileImage.fluid.src}
            altMobile={currentCategory.mobileImage.description}
            title={currentCategory.categoryEncartTitle == null ? currentCategory.categoryName : currentCategory.categoryEncartTitle}
          />
          {/* Container for filtering and results */}
          <div className="flex pb-14">
            {/* Filtering section on left side's page */}
            <div className="w-full hidden md:flex flex-col md:w-3/12" style={classes.filtersContainer}>
              <div className={"flex flex-wrap pl-5"} style={classes.filteringSection}>
                <div className={"w-full h-full py-6 z-40 pb-14"}>
                  <h3 className="font-medium text-semi">Trier par :</h3>
                  <DropdownSelector
                    increasingPrice={() => { setOrderType("asc-price") }}
                    decreasingPrice={() => { setOrderType("desc-price") }}
                    decreasingWeight={() => { setOrderType("desc-weight") }}
                    orderType={orderType}
                  />
                </div>
                {/* Categories navigation checkboxes */}
                <div className={"w-full mx-auto md:mx-0 pb-14 z-30"}>
                  <h3 className="font-medium text-semi">Affiner la <span className="font-medium text-semi text-orange-dark">catégorie</span></h3>
                  <LateralFilterCategories
                    categoryName={currentCategory.categoryEncartTitle == null ? currentCategory.categoryName : currentCategory.categoryEncartTitle}
                    parentItems={parentCategories}
                    labels={data.labelCategories.edges}
                  />
                </div>
                {/* This filter uses Algolia */}
                <div className={"w-5/6 h-full mx-auto md:mx-0 pb-14 z-0"}>
                  <PriceFilter
                    initValues={[currentListMinSearchPrice, currentListMaxSearchPrice]}
                    onPriceChange={([min, max]) => {
                      setSearchPriceMin(min);
                      setSearchPriceMax(max);
                    }}
                    minimumPrice={searchPriceMin}
                    maxPrice={searchPriceMax}
                  />
                </div>
                {/* Filter by sellers with checkboxes */}
                <div className="w-full h-full mx-auto md:mx-0 pb-4 z-30">
                  <LateralFilterSellers
                    sellersName={sellers}
                    selectedSellersArray={selectedSellersArray}
                    filterBySeller={(el) => {
                      // create an array of selected sellers when checkbox is checked
                      const checkedSellerNameValue = el.target.attributes.value.value;
                      selectedSellersArray.includes(checkedSellerNameValue) ?
                        selectedSellersArray.splice(selectedSellersArray.indexOf(checkedSellerNameValue), 1) :
                        selectedSellersArray.push(checkedSellerNameValue);
                      setSearchBySeller([...selectedSellersArray]);
                    }}
                  />
                </div>
                {/* Facets filter */}
                <div className="w-full h-full mx-auto md:mx-0 pb-4 z-30">
                  {
                    data?.facets?.edges[0]?.node?.facets?.map(facetItem => (
                      <LateralFacetFilter
                        attribute={facetItem}
                        facetFilter={(el) => {
                          const checkedFacetsValue = el.target.attributes.value.value;
                          const checkedFacetsCode = facetItem.code;

                          if (selectedFacetsArray.length === 0) {
                            selectedFacetsArray.push({ code: checkedFacetsCode, value: [checkedFacetsValue] })
                          } else if (selectedFacetsArray.length > 0) {
                            let identicalCode = false
                            let indexCode = null
                            selectedFacetsArray.map((facet, index) => {
                              if (facet.code === checkedFacetsCode) {
                                identicalCode = true
                                indexCode = index
                              }
                            })
                            if (identicalCode) {
                              selectedFacetsArray[indexCode].value.includes(checkedFacetsValue) ?
                                selectedFacetsArray[indexCode].value.splice(selectedFacetsArray[indexCode].value.indexOf(checkedFacetsValue), 1) :
                                selectedFacetsArray[indexCode].value.push(checkedFacetsValue);
                            } else {
                              selectedFacetsArray.push({ code: checkedFacetsCode, value: [checkedFacetsValue] });
                            }
                          }
                          setSearchFacets([...selectedFacetsArray]);
                        }}
                      />
                    ))
                  }
                </div>
              </div>
            </div>
            {/* Results section with products cards on right side of the page */}
            <div className={`flex flex-col w-full md:w-9/12 ${(currentProductList && currentProductList.length === 0) ? "justify-start" : "justify-between"} `}>
              <div className={"pt-10 pl-2 2xl:pl-0 grid grid-cols-2 gap-y-8 gap-x-4 gap-x-8 xl:grid-cols-4 2xl:grid-cols-5"}>
                {isLoading && (
                  <Loader />
                )}
                {!isLoading && currentProductList.map((currentProductListItem) => {
                  return (
                    <ProductListCard product={currentProductListItem} />
                  )
                })}
              </div>
              {!isLoading && currentProductList.length === 0 && (
                <div className="mt-4 text-center">
                  <p>Aucun résultat</p>
                </div>
              )}
              {!isLoading && (
                <Pagination
                  page={page}
                  handleClick={(page) => handleClick(page)}
                  lastPage={lastPage}
                />
              )}
              {/* SEO text of current category */}
              {(categoryDescription && categoryDescription.length > 150) && (
                <Subtitle
                  text={categoryDescription} style="text-xxm text-grey-darkest hidden sm:block w-full sm:w-4/5 md:w-2/3 2xl:w-1/2 mx-auto"
                />
              )}
            </div>

            {/* Filter bottom in mobile version */}
            <div className='w-full flex justify-center fixed bottom-8'>
              <button
                className='md:hidden rounded-full bg-black text-white flex justify-center items-center py-2 px-4'
                onClick={() => setIsOpenFilter(!isOpenFilter)}
              >
                Filtres
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 ml-2" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z" clipRule="evenodd" />
                </svg>
              </button>
            </div>
          </div>
        </>
      }

      {!snackBarLoading && <SnackBar open={snackBarOpen} error={snackBarError} message={snackBarMessage} />}

    </Layout>
  );
};

export default PageList;

export const query = graphql`
  query($skip: Int = 0, $limit: Int = 160, $locale: String!, $slug: String!) {
    categoryProducts: allContentfulFicheProduit(filter: {node_locale: {eq: $locale}, secondarySveCategories: {elemMatch: {slug: {eq: $slug}}}} sort: { fields: poids, order: DESC } skip: $skip limit: $limit) {
      edges {
        node {
          attributes {
            goal
            porosity
            careSteps
          }
          cataloger
          poids
          seller {
            corporateName
          }
          title
          slug
          childContentfulFicheProduitProductsFieldJsonNode {
              products {
                gtin
                id
                images
                offers {
                  crossedPriceEndDate
                  crossedPriceStartDate
                  crossedPriceWithVat
                  id
                  isAvailable
                  leadtimeToShip
                  priceWithVat
                  sellerId
                  sellerSku
                }
              }
          }
          cataloger
          flapSelectionZawema
          principalSveCategory {
            categoryName
            slug
          }
          secondarySveCategories {
            categoryName
            slug
          }
        }
        previous {
          slug
        }
      }
    }
    currentCategory: allContentfulCategorySve(
      filter: { slug: { eq: $slug }, node_locale: { eq: $locale } }
    ) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          CategoryDescription {
            raw
          }
          categoryEncartTitle
          desktopImage {
            fluid {
              src
            }
            description
          }
          mobileImage {
            description
            fluid {
              src
            }
          }
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
            parentCategory {
              slug
              categoryName
              categoryEncartTitle
              parentCategory {
                slug
                categoryName
                categoryEncartTitle
                parentCategory {
                  slug
                  categoryName
                  categoryEncartTitle
                  parentCategory {
                    slug
                    categoryName
                    categoryEncartTitle
                  }
                }
              }
            }
          }
        }
      }
    }
    menuFirstLevel: allContentfulCategorySve(filter: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}) {
      edges {
        node {
          slug
          categoryName
        }
      }
    }
    menuSecondLevel: allContentfulCategorySve(filter: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    menuThirdLevel: allContentfulCategorySve(filter: {parentCategory: {parentCategory: { slug: { in: ["femme", "enfant", "homme", "coaching-capillaire"] }, node_locale: {eq: $locale}}}}
    sort : {fields: [slug], order: ASC}) {
      edges {
        node {
          slug
          categoryName
          categoryEncartTitle
          parentCategory {
            slug
            categoryName
            categoryEncartTitle
            parentCategory {
              slug
              categoryName
              categoryEncartTitle
            }
          } 
        }
      }
    }
    labelCategories: allContentfulCategorySve(filter: { parentCategory: {slug: {eq: $slug}, node_locale: { eq: $locale }}}) { 
      edges {
        node {
          categoryName
          categoryEncartTitle
          slug
          parentCategory {
            categoryName
            categoryEncartTitle
          }
        }
      }
    }
    categorySellers : allContentfulSellerModel(filter: { node_locale: { eq: $locale } }) {
      edges {
        node {
          corporateName
        }
      }      
    }
    facets: allContentfulCategorySve(filter: { slug: { eq: $slug }, node_locale: { eq: $locale }}) { 
      edges {
        node {
          facets {
            ... on ContentfulAttribute {
              name
              code
              valuesCodes
              values
            }
          }
        }
      }
    }
    categoryImages: allContentfulAsset(filter: {node_locale: {eq: $locale}}) {
      edges {
        node {
          title
          file {
            url
          }
          description
          contentful_id
        }
      }
    }
  }
`;